import {nfse_decorators} from "./nfse.decorators";
import {nfe_decorators} from "./nfe.decorators";
import {cte_decorators} from "./cte.decorators";
import {admin_decorators} from "./admin.decorators";
import {actions_decorators} from "./actions.decorators";
import {descarga_decorators} from "./descarga.decorators";
import { curadoria_decorators } from './curadoria.decoratorr';
import { nfe_transferencia_decorators } from './nfe_transferencia.decorators';
import { nfe_acompanhamento_decorators } from './nfe_acompanhamento.decorators';
import { nfe_retorno_remessa_decorators } from './nfe_retorno_remessa.decorators';
import { nfe_devolucao_decorators } from './nfe_devolucao.decorators';
import { nfe_writer_decorators } from './nfe_writer.decorators';
import { nfse_writer_decorators } from './nfse_writer.decorators';
import { cte_writer_decorators } from './cte_writer.decorators';
import { recomendacao_decorators } from './recomendacao.decorators';
import { pendencia_curadoria_rel_decorators } from "@decorators/pendencia-curadoria-rel.decorators";


export function EnvironmentDecorator() {
  const decorators = [nfse_decorators,
                      nfse_writer_decorators,
                      nfe_decorators,
                      nfe_transferencia_decorators,
                      nfe_acompanhamento_decorators,
                      nfe_writer_decorators,
                      nfe_retorno_remessa_decorators,
                      nfe_devolucao_decorators,
                      cte_decorators,
                      cte_writer_decorators,
                      descarga_decorators,
                      curadoria_decorators,
                      admin_decorators,
                      recomendacao_decorators,
                      actions_decorators,
                      pendencia_curadoria_rel_decorators];

  return (target: any, key: string) => {
    const descriptor = Object.getOwnPropertyDescriptor(target, key) || {};

    var labelProvider = [];
    decorators.forEach(decorator => {
      labelProvider[decorator['nome'].toLocaleLowerCase()] = decorator;
   });
    descriptor.value = labelProvider;
    Object.defineProperty(target, key, descriptor);
};
}

